<template>
  <div class="home">
    <div class="hero-section">
      <div class="hero-container">
        <div class="w-layout-grid hero-grid resetMargin">
          <div id="w-node-e69f98cd911c-0ba49fb5" class="hero-image-container">
            <img
              src="images/vedanta-hero.png"
              loading="lazy"
              width="280"
              height=""
              alt=""
              class="hero-image"
            />
          </div>
          <div id="w-node-5fa58fba220e-0ba49fb5" class="carousel-container">
            <div class="hero-text-top">
              <div class="hero-text-container">
                Improve your intellect<br />Improve your life
              </div>
            </div>
            <div class="hero-button-container cursorLinkPointer" @click="$router.push('/activities')">
              <div class="button-container">
                <div class="button-text">
                  <div class="button-text">JOIN OUR NEXT SESSION</div>
                </div>
                <div class="button-arrow hero">
                    <lottie-animation
                      :animation="ButtonArrowAnimation"
                      :animationSpeed="1.5"
                    ></lottie-animation>

                  <!-- <div data-w-id="f0f9fafa-b0ba-74ad-58a0-24eed6d069bf" data-animation-type="lottie" data-src="documents/join-our-next-session-icon.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="1.75" data-duration="0"></div> -->
                </div>
              </div>
            </div>
          </div>

          <div
            id="w-node-87e39272c327-0ba49fb5"
            class="what-is-vedanta-container"
          >
            <div class="title-text-container">
              <div class="title-text hero">What is Vedanta?</div>
            </div>
            <div class="content-text-container">
              <div class="content-text">
                Vedanta is derived from two Sanskrit words,
                <strong><em>Veda</em></strong> knowledge and
                <strong><em>anta</em></strong> end.<br /><strong
                  ><em
                    >Vedanta means the culmination of knowledge. It is the
                    ancient Indian philosophy which answers the fundamental
                    questions of life</em
                  ></strong
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section vedanta-in-relationships">
      <div class="page-container">
        <div class="w-layout-grid home-2-grid homepage">
          <div id="w-node-e9dbaa3995f1-0ba49fb5" class="content-text-container">
            <div class="content-text">
              Vedanta is a science which deals with human development. It
              provides formulas and theorems to improve our lives. It approaches
              the human pursuit of happiness logically and systematically. The
              eternal truths of Vedanta are not based on any individual. The
              knowledge is universally applicable in every facet of life.
              Vedanta promotes self-development, combining dynamic action with
              mental peace, culminating in Self-Realisation, Human
              Perfection.<br />
            </div>
          </div>
          <div id="w-node-a5d685c13aef-0ba49fb5" class="title-text-container">
            <div class="title-text left-aligned">Vedanta in Relationships</div>
          </div>
          <div id="w-node-f511ffd36cc9-0ba49fb5" class="content-text-container">
            <div class="content-text center-aligned">
              In life we have various relationships. With family, friends,
              colleagues or even pets. Lacking the ability to relate properly
              could lead to frustration and stress, conflict and confrontation.
              It is not whom or what you meet in life that matters but how you
              meet them.
            </div>
          </div>
          <div id="w-node-d2ea293b7efd-0ba49fb5" class="content-text-container">
            <div class="content-text  center-aligned">
              Vedanta teaches the Art of Right Relation rather than whom or what
              to relate to. This helps in leading smooth and enjoyable
              relationships.
            </div>
          </div>
           <div id="w-node-215443319777-0ba49fb5" class="image-container"><img src="images/vedanta-spain-in-relationships.svg" loading="lazy" alt=""></div>
        </div>
      </div>
    </div>
    <div class="page-section vedanta-at-work">
      <div class="page-container">
        <div class="w-layout-grid home-2-grid vedanta-at-work">
          <div id="w-node-f9ef8260d0dc-0ba49fb5" class="title-text-container">
            <div class="title-text left-aligned">Vedanta at Work</div>
          </div>
          <div id="w-node-f9ef8260d0df-0ba49fb5" class="content-text-container">
            <div class="content-text center-aligned">
              One excels in his field of activity by achieving something
              exceptional compared to his peers. Millions of doctors have
              graduated from medical school, but one discovered how to
              transplant a kidney. The difference is that such a person
              possesses a powerful intellect. Intellect is not intelligence,
              which is information acquired from external agencies. It is the
              capacity to think, reason, judge and decide. It is the
              prerequisite for processing and applying intelligence.
            </div>
          </div>
          <div id="w-node-f9ef8260d0e2-0ba49fb5" class="content-text-container">
            <div class="content-text center-aligned italic">
              Vedanta enables you to develop a strong intellect helping you to
              perform productive actions, gain, success and manage duties while
              maintaining peace of mind.
            </div>
          </div>
          <div id="w-node-5b4fd74528d8-0ba49fb5" class="image-container">
            <img
              src="images/vedanta-at-work.svg"
              loading="lazy"
              alt="vedanta-at-work"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
// @ is an alias to /src
import ButtonArrow from '../assets/LottieAnimations/lottieflow-play-03-ffffff-easey.json'
import LottieAnimation from '../components/LottieAnimationComponent'

export default {
  name: 'Home',
  components: {
    LottieAnimation
  },
  data () {
    return {
      ButtonArrowAnimation: ButtonArrow
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>
<style>
.resetMargin {
  margin-top: 30px !important;
}
@media screen and (max-width: 479px) {
.resetMargin {
    margin-top: 20px !important;
}
}
</style>
